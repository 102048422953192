.progressBarwrapper {
    border-radius: 10px;
}

.progressBarSmall {
    width: 100px;
}

.progressBarBig {
    width: 150px;
}

.btn-primary-custom:hover {
    background-color: #E5E5E5 !important;
    border-color: #FFFFFF !important;
}

.menu-item-container:hover {
    background-color: #F2F2F2 ! important;
}

.menu-item-raise:hover {
    margin-top: -10px ! important;
}

.btn-main {
    background-color: #E2E2E2 !important;
}